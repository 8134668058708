import { Modal } from "flowbite-react";
import React from "react";
import OfferingForm from "./OfferingForm";

const OfferingEditModal = ({
  chatbotUuid,
  show,
  setShowCallback,
  editOfferingId,
}) => {
  return (
    <Modal show={show} popup dismissible onClose={() => setShowCallback(false)}>
      <Modal.Header />
      <Modal.Body>
        <div className="mb-4 text-sm font-light text-gray-500 dark:text-gray-400">
          <h3 className="mb-3 text-2xl font-bold text-gray-900 dark:text-white">
            Klantvoorsel wijzigen 🎉
          </h3>
          <p>
            Pas de gegevens aan en klik op de knop "Opslaan" om het
            klantvoorstel bij te werken.
          </p>
          <OfferingForm
            chatbotUuid={chatbotUuid}
            successCallback={() => setShowCallback(false)}
            offeringId={editOfferingId}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default OfferingEditModal;
