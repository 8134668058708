import React from "react";
import QuestionIcon from "./icons/QuestionIcon";
import { Link } from "react-router-dom";

const questions = [
  {
    question: "Wat zijn Klant Aanbiedingen?",
    answer: (
      <>
        Klant Aanbiedingen zijn aanbiedingen die u aan uw klant wilt doen. Deze
        aanbiedingen verschijnen in de chatbot direct na het genereren van een
        website. Uw klant kan hierop doorklikken naar uw site en de aanbieding
        bekijken. U kunt Klantvoorstellen aanmaken in het dashboard onder het
        kopje{" "}
        <Link
          className="text-blue-600 hover:underline"
          to="/dashboard/offerings"
        >
          Klant Aanbiedingen
        </Link>
        .
      </>
    ),
  },
  {
    question: "Wanneer wordt een gebruiker een Lead?",
    answer: (
      <>
        Een gebruiker wordt een Lead wanneer deze zich heeft aangemeld om de
        chat te gebruiken. Dit gebeurt wanneer de gebruiker een chat start met
        de chatbot op uw website. Indien de gebruiker een website heeft
        gegenereerd wordt de lead verrijkt met de gegevens van de website. U
        kunt deze leads bekijken in het menu onder het kopje{" "}
        <Link className="text-blue-600 hover:underline" to="/dashboard/leads">
          Leads
        </Link>
        .
      </>
    ),
  },
  {
    question: "Wat zijn Chats?",
    answer: (
      <>
        In dit overzicht ziet u alle chats die klanten via uw chatbot hebben
        gevoerd. U kunt hier de chats bekijken om te zien wat er besproken is. U
        vindt dit overzicht in het menu onder het kopje{" "}
        <Link className="text-blue-600 hover:underline" to="/dashboard/leads">
          Chats
        </Link>
        .
      </>
    ),
  },
  {
    question: "Kan ik een feature request indienen?",
    answer: (
      <>
        Ja, u kunt een feature request indienen door een e-mail te sturen naar{" "}
        <a
          className="text-blue-600 hover:underline"
          href="mailto:support@sitechatpro.nl"
        >
          support@sitechatpro.nl
        </a>
        . Bij genoeg animo nemen wij uw verzoek in behandeling en zullen u op de
        hoogte houden van de voortgang.
      </>
    ),
  },
];

export default function Faq() {
  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="bg-white rounded shadow p-6">
        <h2 className="mb-8 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
          Veelgestelde vragen
        </h2>
        <div className="grid pt-8 text-left border-t border-gray-200 md:gap-4 dark:border-gray-700 md:grid-cols-2">
          {questions.map((q, i) => (
            <div key={i} className="mb-10">
              <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">
                <QuestionIcon />
                {q.question}
              </h3>
              <p className="text-gray-500 dark:text-gray-400">{q.answer}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
