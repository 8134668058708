import React, { useEffect } from "react";
import { useMenuVisibility } from "./hooks/menu-visibility";
import { COMPANY_NAME, SITE_URL } from "./constants";
import myLogo from "/logo.png";
import SettingsIcon from "./icons/SettingsIcon";
import TopbarSearch from "./components/TopbarSearch";
import SidebarSearch from "./components/SidebarSearch";
import { Link, Outlet } from "react-router-dom";
import SidebarBottomMenu from "./components/SidebarBottomMenu";
import PieChartIcon from "./icons/PieChartIcon";
import ShoppingBagIcon from "./icons/ShoppingBagIcon";
import DocumentIcon from "./icons/DocumentIcon";
import InboxIcon from "./icons/InboxIcon";
import ClipboardIcon from "./icons/ClipboardIcon";
import LifeBoeiIcon from "./icons/LifeBoeiIcon";
import withRequiresAuth from "./hoc/requires-auth";
import useUserStore from "./hooks/user-store";
import { md5 } from "js-md5";
import SalePercentIcon from "./icons/SalePercentIcon";

const asideMenu = [
  { name: "Dashboard", path: "/dashboard", iconComponent: <PieChartIcon /> },
  {
    name: "Leads",
    path: "/dashboard/leads",
    iconComponent: <SalePercentIcon />,
  },
  { name: "Chats", path: "/dashboard/chats", iconComponent: <InboxIcon /> },
  {
    name: "Chatbot Instellingen",
    path: "/dashboard/settings",
    iconComponent: <SettingsIcon />,
    hasLine: true,
  },
  {
    name: "Klant Aanbiedingen",
    path: "/dashboard/offerings",
    iconComponent: <DocumentIcon />,
  },
  {
    name: "Facturatie",
    path: "/dashboard/billing",
    iconComponent: <ShoppingBagIcon />,
  },
  {
    name: "Technische Documentatie",
    path: "/dashboard/documentation",
    iconComponent: <ClipboardIcon />,
    hasLine: true,
  },
  {
    name: "Veelgestelde vragen",
    path: "/dashboard/faq",
    iconComponent: <LifeBoeiIcon />,
  },
];

function emailToGravatar(email) {
  // gravatar link is made by stripping whitespace and converting to lowercase
  // then hashing the email with md5
  // then appending it to the gravatar url

  const hash = md5(email.trim().toLowerCase());
  return `https://www.gravatar.com/avatar/${hash}`;
}

function Dashboard() {
  const { isMenuVisible, setMenuVisible } = useMenuVisibility();
  const userData = useUserStore((state) => state.userData);
  useEffect(() => {
    if (isMenuVisible) {
      setMenuVisible(false);
    }
    return () => {
      setMenuVisible(true);
    };
  }, []);

  return (
    <div className="antialiased bg-gray-50 dark:bg-gray-900">
      <nav className="bg-white border-b border-gray-200 px-4 py-2.5 dark:bg-gray-800 dark:border-gray-700 fixed left-0 right-0 top-0 z-50">
        <div className="flex flex-wrap justify-between items-center">
          <div className="flex justify-start items-center">
            <button
              data-drawer-target="drawer-navigation"
              data-drawer-toggle="drawer-navigation"
              aria-controls="drawer-navigation"
              className="p-2 mr-2 text-gray-600 rounded-lg cursor-pointer md:hidden hover:text-gray-900 hover:bg-gray-100 focus:bg-gray-100 dark:focus:bg-gray-700 focus:ring-2 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >
              <svg
                aria-hidden="true"
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <svg
                aria-hidden="true"
                className="hidden w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Toggle sidebar</span>
            </button>
            <Link
              to={SITE_URL}
              className="flex items-center justify-between mr-4"
            >
              <img
                src={myLogo}
                className="mr-3 h-8"
                alt={`${COMPANY_NAME} logo`}
              />
              <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
                {COMPANY_NAME}
              </span>
            </Link>
            <TopbarSearch />
          </div>
          <div className="flex items-center lg:order-2">
            {/* this button shows when screen is small enough that sidebar collapses, when you click on it it opens the sidebar and lets you use search */}
            {/* <button */}
            {/*   type="button" */}
            {/*   data-drawer-toggle="drawer-navigation" */}
            {/*   aria-controls="drawer-navigation" */}
            {/*   className="p-2 mr-1 text-gray-500 rounded-lg md:hidden hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600" */}
            {/* > */}
            {/*   <span className="sr-only">Toggle search</span> */}
            {/*   <svg */}
            {/*     aria-hidden="true" */}
            {/*     className="w-6 h-6" */}
            {/*     fill="currentColor" */}
            {/*     viewBox="0 0 20 20" */}
            {/*     xmlns="http://www.w3.org/2000/svg" */}
            {/*   > */}
            {/*     <path */}
            {/*       clipRule="evenodd" */}
            {/*       fillRule="evenodd" */}
            {/*       d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" */}
            {/*     ></path> */}
            {/*   </svg> */}
            {/* </button> */}
            {/* <NotificationBell /> */}
            {/* <AppGrid /> */}
            <button
              type="button"
              className="flex mx-3 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
              id="user-menu-button"
              aria-expanded="false"
              data-dropdown-toggle="dropdown"
            >
              <span className="sr-only">Open user menu</span>
              <img
                className="w-8 h-8 rounded-full"
                src={emailToGravatar(userData.email)}
                alt={userData.fullname}
              />
            </button>
            <div
              className="hidden z-50 my-4 w-56 text-base list-none bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600 rounded-xl"
              id="dropdown"
            >
              <div className="py-3 px-4">
                <span className="block text-sm font-semibold text-gray-900 dark:text-white">
                  {userData.fullname}
                </span>
                <span className="block text-sm text-gray-900 truncate dark:text-white">
                  {userData.email}
                </span>
              </div>
              <ul
                className="py-1 text-gray-700 dark:text-gray-300"
                aria-labelledby="dropdown"
              >
                <li>
                  <Link
                    to="/profile"
                    className="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white"
                  >
                    My profile
                  </Link>
                </li>
                <li>
                  <a
                    href="#"
                    className="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white"
                  >
                    Account settings
                  </a>
                </li>
              </ul>
              <ul
                className="py-1 text-gray-700 dark:text-gray-300"
                aria-labelledby="dropdown"
              >
                <li>
                  <a
                    href="#"
                    className="flex items-center py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    <svg
                      className="mr-2 w-5 h-5 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    My likes
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="flex items-center py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    <svg
                      className="mr-2 w-5 h-5 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path>
                    </svg>
                    Collections
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="flex justify-between items-center py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    <span className="flex items-center">
                      <svg
                        aria-hidden="true"
                        className="mr-2 w-5 h-5 text-primary-600 dark:text-primary-500"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      Pro version
                    </span>
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </a>
                </li>
              </ul>
              <ul
                className="py-1 text-gray-700 dark:text-gray-300"
                aria-labelledby="dropdown"
              >
                <li>
                  <a
                    href="#"
                    className="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    Sign out
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>

      <aside
        className="fixed top-0 left-0 z-40 w-64 h-screen pt-14 transition-transform -translate-x-full bg-white border-r border-gray-200 md:translate-x-0 dark:bg-gray-800 dark:border-gray-700"
        aria-label="Sidenav"
        id="drawer-navigation"
      >
        <div className="overflow-y-auto py-5 px-3 h-full bg-white dark:bg-gray-800">
          <SidebarSearch />
          <ul className="space-y-2">
            {asideMenu.map((item) => (
              <li
                key={item.name}
                className={
                  item.hasLine
                    ? "pt-5 space-y-2 border-t border-gray-200 dark:border-gray-700"
                    : ""
                }
              >
                <Link to={item.path}>
                  <button
                    type="button"
                    className="flex items-center p-2 w-full text-base font-medium text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >
                    {item.iconComponent}
                    <span className="flex-1 ml-3 text-left whitespace-nowrap">
                      {item.name}
                    </span>
                  </button>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <SidebarBottomMenu />
      </aside>

      <main className="p-4 md:ml-64 h-auto pt-20">
        <Outlet />
        {/* <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-4"> */}
        {/*   <div className="border-2 border-dashed border-gray-300 rounded-lg dark:border-gray-600 h-32 md:h-64"></div> */}
        {/*   <div className="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-32 md:h-64"></div> */}
        {/*   <div className="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-32 md:h-64"></div> */}
        {/*   <div className="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-32 md:h-64"></div> */}
        {/* </div> */}
        {/* <div className="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-96 mb-4"></div> */}
        {/* <div className="grid grid-cols-2 gap-4 mb-4"> */}
        {/*   <div className="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-48 md:h-72"></div> */}
        {/*   <div className="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-48 md:h-72"></div> */}
        {/*   <div className="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-48 md:h-72"></div> */}
        {/*   <div className="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-48 md:h-72"></div> */}
        {/* </div> */}
        {/* <div className="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-96 mb-4"></div> */}
        {/* <div className="grid grid-cols-2 gap-4"> */}
        {/*   <div className="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-48 md:h-72"></div> */}
        {/*   <div className="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-48 md:h-72"></div> */}
        {/*   <div className="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-48 md:h-72"></div> */}
        {/*   <div className="border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-48 md:h-72"></div> */}
        {/* </div> */}
      </main>
    </div>
  );
}

export default withRequiresAuth(Dashboard);
