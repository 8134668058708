import React, { useState } from "react";
import ApiService from "./api";

const FileUploader = ({
  id,
  setUrlCallback,
  fileUrl,
  required,
  defaultUrl,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    try {
      const api = new ApiService();
      const logoUrl = await api.uploadFile(file);
      setUrlCallback(logoUrl);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const clear = () => {
    setUrlCallback(null);
  };

  return (
    <>
      <div className="flex items-center space-x-4">
        <div className="flex w-24 h-24 items-center justify-center border border-gray-200 border-dashed rounded-md bg-gray-100 border-gray-200 dark:border-gray-800 dark:bg-gray-950">
          <img
            alt="Logo preview"
            src={fileUrl || defaultUrl}
            className="p-1 object-contain max-w-24 max-h-24"
          />
        </div>
        <div className="flex grow flex-col space-y-2">
          <input
            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
            aria-describedby="file_input_help"
            id={id}
            required={required}
            type="file"
            onChange={handleFileChange}
          />
          <button
            type="button"
            onClick={clear}
            className="self-start text-red-600 inline-flex items-center hover:text-white border border-red-600 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900"
          >
            <svg
              className="mr-1 -ml-1 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
            Clear
          </button>
        </div>
      </div>
    </>
  );
};

export default FileUploader;
