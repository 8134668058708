import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useApiService } from "./hooks/apiService";

function toDatetimeLocalInputCompatibleLocalDate(date) {
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  const pad = (num) => String(num).padStart(2, "0");

  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

function OfferingForm({ chatbotUuid, successCallback, offeringId }) {
  const { apiService } = useApiService();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: async () => loadOfferings(),
  });

  async function loadOfferings() {
    // setLoading(true);
    let offeringsRes = await apiService.getOffering(chatbotUuid, offeringId);
    console.log(offeringsRes);
    for (const key in offeringsRes) {
      if (Object.hasOwnProperty.call(offeringsRes, key)) {
        let element = offeringsRes[key];
        // console.log("key", key, offeringsRes[key]);
        if (["start_date", "end_date"].includes(key)) {
          // convert the utc date back to local time
          let date = new Date(element);
          element = toDatetimeLocalInputCompatibleLocalDate(date);
          // console.log("element", element, key);
          offeringsRes[key] = element;
        }
      }
    }
    return offeringsRes;
    // setLoading(false);
  }
  useEffect(() => {
    if (offeringId) {
      console.log("offeringId", offeringId);
      loadOfferings();
    }
  }, []);

  const onSubmit = async (data) => {
    console.log(data.start_date, data.end_date);
    console.log(new Date().getTimezoneOffset());
    // toISOString() will convert the date to UTC time, no need to do anything special here
    data.start_date = data.start_date
      ? new Date(data.start_date).toISOString()
      : null;
    data.end_date = data.end_date
      ? new Date(data.end_date).toISOString()
      : null;
    console.log("data", data);
    if (offeringId) {
      let res = await toast.promise(
        apiService.updateOffering(chatbotUuid, offeringId, data),
        {
          pending: "Updating offering... 📦",
          success: "Offering updated successfully 🎉",
          error: "Failed to update offering 😢",
        },
      );
      console.log("res", res);
    } else {
      let res = await toast.promise(apiService.addOffering(chatbotUuid, data), {
        pending: "Adding new offering... 📦",
        success: "Offering added successfully 🎉",
        error: "Failed to add offering 😢",
      });
      console.log("res", res);
    }

    successCallback();
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="grid gap-4 mb-4 sm:grid-cols-1 sm:gap-6 sm:mb-5"
    >
      <div>
        <label
          htmlFor="name"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Klantvoorstel naam
        </label>
        <input
          type="text"
          id="name"
          className="form-input mt-1 block w-full rounded-lg"
          {...register("name", { required: "Offering name is required" })}
        />
        {errors.name && (
          <span className="text-red-500">{errors.name.message}</span>
        )}
      </div>
      <div>
        <label
          htmlFor="description"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Beschrijving
        </label>
        <textarea
          id="description"
          rows={3}
          className="form-textarea mt-1 block w-full rounded-lg"
          {...register("description", {
            required: "Description is required",
          })}
        ></textarea>
        {errors.description && (
          <span className="text-red-500">{errors.description.message}</span>
        )}
      </div>
      <div>
        <label
          htmlFor="one_time_price"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Eenmalige prijs
        </label>
        <input
          type="number"
          id="one_time_price"
          className="form-input mt-1 block w-full rounded-lg"
          {...register("one_time_price", { required: "Price is required" })}
        />
        {errors.one_time_price && (
          <span className="text-red-500">{errors.one_time_price.message}</span>
        )}
      </div>
      <div>
        <label
          htmlFor="price"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Periodieke Prijs
        </label>
        <input
          type="number"
          id="price"
          className="form-input mt-1 block w-full rounded-lg"
          {...register("price", { required: "Price is required" })}
        />
        {errors.price && (
          <span className="text-red-500">{errors.price.message}</span>
        )}
      </div>
      <div>
        <label
          htmlFor="payment_period"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Betaal frequentie
        </label>
        <select
          id="payment_period"
          className="form-select mt-1 block w-full rounded-lg"
          {...register("payment_period", {
            required: "Payment period is required",
          })}
        >
          <option value="">Select a period</option>
          <option value="monthly">Monthly</option>
          <option value="yearly">Yearly</option>
          <option value="quarterly">Quarterly</option>
        </select>
        {errors.payment_period && (
          <span className="text-red-500">{errors.payment_period.message}</span>
        )}
      </div>
      <div>
        <label
          htmlFor="more_information_url"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Meer informatie URL
        </label>
        <input
          type="url"
          id="more_information_url"
          className="form-input mt-1 block w-full rounded-lg"
          {...register("more_information_url")}
        />
        {errors.more_information_url && (
          <span className="text-red-500">
            {errors.more_information_url.message}
          </span>
        )}
      </div>
      <div>
        <label
          htmlFor="start_date"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Start datum
        </label>
        <input
          type="datetime-local"
          id="start_date"
          className="form-input mt-1 block w-full rounded-lg"
          {...register("start_date")}
        />
        {errors.start_date && (
          <span className="text-red-500">{errors.start_date.message}</span>
        )}
      </div>
      <div>
        <label
          htmlFor="end_date"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Eind datum
        </label>
        <input
          type="datetime-local"
          id="end_date"
          className="form-input mt-1 block w-full rounded-lg"
          {...register("end_date")}
        />
        {errors.end_date && (
          <span className="text-red-500">{errors.end_date.message}</span>
        )}
      </div>
      <div>
        <label
          htmlFor="end_date"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Actief
        </label>
        <input
          type="checkbox"
          id="active"
          className="form-input mt-1 block rounded-lg"
          {...register("active")}
        />
        {errors.active && (
          <span className="text-red-500">{errors.active.message}</span>
        )}
      </div>
      <button
        type="submit"
        className="mt-4 bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
      >
        Klantvoorstel opslaan
      </button>
    </form>
  );
}

export default OfferingForm;
