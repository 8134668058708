import React from "react";
import { useEffect, useState } from "react";
import Pagination from "./Pagination";
import withRequiresAuth from "./hoc/requires-auth";
import { Link } from "react-router-dom";
import { useApiService } from "./hooks/apiService";
import { printDateInUserLocale } from "./utils/date";
import useUserStore from "./hooks/user-store";
import ChatStatus from "./components/ChatStatus";

function Chats() {
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [chatbotUUID, setChatbotUUID] = useState("");
  const { apiService } = useApiService();
  const updateUserState = useUserStore((state) => state.updateUserState);
  const chatbotState = useUserStore((state) => state?.userData?.chatbot);

  async function getChatbotUUID() {
    if (!chatbotState) {
      let response = await apiService.getMyChatbotSettings();
      const firstChatbot = response[0];
      setChatbotUUID(firstChatbot.uuid);
      updateUserState({
        chatbot: firstChatbot,
      });
    } else {
      setChatbotUUID(chatbotState.uuid);
    }
  }

  async function getTableData() {
    if (chatbotUUID !== "") {
      let data = await apiService.getChatbotConversations(
        chatbotUUID,
        currentPage,
      );
      setTableData(data.conversations);
      setTotalItems(data.total_items);
    }
  }

  useEffect(() => {
    getChatbotUUID();
  }, []);

  useEffect(() => {
    getTableData();
  }, [chatbotUUID, currentPage]);

  return (
    <section className="bg-gray-50 dark:bg-gray-900 py-3 sm:py-5">
      <div className="mx-auto lg:px-8">
        <div className="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
          <div className="flex flex-col px-4 py-3 space-y-3 lg:flex-row lg:items-center lg:justify-between lg:space-y-0 lg:space-x-4">
            <div className="flex items-center flex-1 space-x-4">
              <h5>
                <span className="text-gray-500">
                  Deze tabel toont alle chats die klanten via uw chatbot
                  verricht hebben{" "}
                </span>
              </h5>
            </div>
            <div className="flex flex-col flex-shrink-0 space-y-3 md:flex-row md:items-center lg:justify-end md:space-y-0 md:space-x-3">
              {/* <button */}
              {/*   type="button" */}
              {/*   className="flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" */}
              {/* > */}
              {/*   <svg */}
              {/*     className="w-4 h-4 mr-2" */}
              {/*     xmlns="http://www.w3.org/2000/svg" */}
              {/*     fill="none" */}
              {/*     viewbox="0 0 24 24" */}
              {/*     stroke-width="2" */}
              {/*     stroke="currentColor" */}
              {/*     aria-hidden="true" */}
              {/*   > */}
              {/*     <path */}
              {/*       stroke-linecap="round" */}
              {/*       stroke-linejoin="round" */}
              {/*       d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" */}
              {/*     /> */}
              {/*   </svg> */}
              {/*   Export to CSV */}
              {/* </button> */}
            </div>
          </div>
          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="p-4">
                    <div className="flex items-center">
                      <input
                        id="checkbox-all"
                        type="checkbox"
                        className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      ></input>
                      <label htmlFor="checkbox-all" className="sr-only">
                        checkbox
                      </label>
                    </div>
                  </th>
                  <th scope="col" className="px-4 py-3">
                    ID
                  </th>
                  <th scope="col" className="px-4 py-3">
                    User
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Status
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Message Count
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Gemaakt op
                  </th>
                  <th scope="col" className="px-4 py-3">
                    Laatste update
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row) => (
                  <tr
                    key={row.conversation_id}
                    className="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700"
                  >
                    <td className="w-4 px-4 py-3">
                      <div className="flex items-center">
                        <input
                          id="checkbox-table-search-1"
                          type="checkbox"
                          onClick={(event) => event.stopPropagation()}
                          className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        ></input>
                        <label
                          htmlFor="checkbox-table-search-1"
                          className="sr-only"
                        >
                          checkbox
                        </label>
                      </div>
                    </td>
                    <th
                      scope="row"
                      className="flex items-center px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      <Link
                        to={`/dashboard/chats/${row.conversation_id}`}
                        className="hover:underline"
                      >
                        {row.conversation_id}
                        <svg
                          className="w-4 h-4 text-gray-800 dark:text-white inline-block ml-1"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeWidth="2"
                            d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z"
                          />
                          <path
                            stroke="currentColor"
                            strokeWidth="2"
                            d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                          />
                        </svg>
                      </Link>
                      {/* put a eye view icon here */}
                    </th>
                    <td className="px-4 py-2">
                      <span className="bg-primary-100 text-primary-800 text-xs font-medium px-2 py-0.5 rounded dark:bg-primary-900 dark:text-primary-300">
                        {row.user.username}
                      </span>
                    </td>
                    <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      <ChatStatus status={row.status} />
                    </td>
                    <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {row.message_count}
                    </td>
                    <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {printDateInUserLocale(row.created_at)}
                    </td>
                    <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {printDateInUserLocale(row.last_message?.timestamp)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            currentPage={currentPage}
            pageSize={25}
            totalItems={totalItems}
            onPageChange={(page) => {
              setCurrentPage(page);
            }}
          />
        </div>
      </div>
    </section>
  );
}
export default withRequiresAuth(Chats);
