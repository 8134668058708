import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "flowbite";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Features from "./Features";
import Home from "./Home";
import ErrorPage from "./ErrorPage";
import Pricing from "./Pricing";
import Privacy from "./Privacy";
import Profile from "./Profile";
import Trial from "./Trial";
import Trial2 from "./Trial2";
import TrialConfirm from "./TrialConfirm";
import Offerings from "./Offerings";
import StatePoc from "./StatePoc";
import TermsOfService from "./TermsOfService";
import { CallbackPage } from "./CallbackPage";
import Dashboard from "./Dashboard";
import Billing from "./Billing";
import Faq from "./Faq";
import Settings from "./Settings";
import Chats from "./Chats";
import Login from "./Login";
import Chat from "./Chat";
import Documentation from "./Documentation";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "callback",
        element: <CallbackPage />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "features",
        element: <Features />,
      },
      {
        path: "prijzen",
        element: <Pricing />,
      },
      {
        path: "privacy-beleid",
        element: <Privacy />,
      },
      {
        path: "gebruiksvoorwaarden",
        element: <TermsOfService />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "trial",
        element: <Trial />,
      },
      {
        path: "trial/setup-chat",
        element: <Trial2 />,
      },
      {
        path: "trial/confirm",
        element: <TrialConfirm />,
      },
      {
        path: "state-poc",
        element: <StatePoc />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
        children: [
          {
            path: "",
            element: <div>Dashboard</div>,
          },
          {
            path: "settings",
            element: <Settings />,
          },
          {
            path: "billing",
            element: <Billing />,
          },
          {
            path: "leads",
            element: <div>Leads</div>,
          },
          {
            path: "offerings",
            element: <Offerings />,
          },
          {
            path: "chats",
            element: <Chats />,
          },
          {
            path: "chats/:conversationId",
            element: <Chat />,
          },
          {
            path: "documentation",
            element: <Documentation />,
          },
          {
            path: "faq",
            element: <Faq />,
          },
        ],
      },
    ],
  },
]);

// React.StrictMode will render components twice in development mode, this can lead to useEffect being called twice leading to double API calls.
// Just so you know (y)
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Suspense fallback={<div>Loading...</div>}>
      <RouterProvider router={router}></RouterProvider>
    </Suspense>
  </React.StrictMode>,
);
